import React from 'react'
import imageFilter1 from '../../assets/img/filter_img1.jpeg';
import imageFilter2 from '../../assets/img/filter_img2.jpeg';

export default function Filter() {
    return (
        <div className='filter_page'>
            <div className="container__partspage">
                <h2 className="content__title">Фильтр</h2>
                <p className="text">Оригинальные фильтры DEVELON спроектированы и разработаны специально для машин DEVELON.
                    Они созданы в соответствии с нашими строгими критериями и тем самым обеспечивают надежную и высококачественную
                    фильтрацию.
                </p>
                <img className='filterImage' src={imageFilter1} alt="imageFilter1" />
                <h4 className="content__podtitle">Оригинальный фильтр DEVELON предоставляет</h4>
                <div className="section__grand">
                    <ul className='ul__grand'>
                        <li className="grand grand__back_one"><p>Выдающуюся эффективность фильтрации и низкие затраты на техническое обслуживание.</p></li>
                        <li className="grand"><p>Отличная DHC (пылеемкость) и стабильная производительность фильтрации.</p></li>
                        <li className="grand"><p>Оптимизированная конструкция для машин DEVELON и превосходное качество,
                            проверенное в суровых условиях испытаний.</p></li>
                        <li className="grand"><p>Увеличенный срок службы и обширная площадь фильтрации.</p></li>
                        <li className="grand"><p>Позволяет увеличить время безотказной работы машины и повысить производительность.</p></li>
                        <li className="grand"><p>Сохраняет остаточную стоимость вашей машины.</p></li>
                    </ul>
                </div>
                <h4 className="content__podtitle">Высокоэффективный масляный фильтр двигателя</h4>
                <img className='filterImage' src={imageFilter2} alt="imageFilter2" />
                <div className="section__grand">
                    <ul className='ul__grand'>
                        <li className="grand grand__back_one">
                            <h5 className="title">1. Усиленный корпус</h5>
                            <p>Превосходно защищает фильтр от внешних воздействий.</p>
                        </li>
                        <li className="grand">
                            <h5>4. Спиральная центральная трубка</h5>
                            <p>Прочная конструкция для работы под высоким давлением.</p>
                        </li>
                        <li className="grand">
                            <h5>2. Высококачественный материал</h5>
                            <p>Двухслойный фильтрующий материал повышает эффективность фильтрации и DHC
                                , защищая детали двигателя и продлевая срок службы фильтра.</p>
                        </li>
                        <li className="grand">
                            <h5>5. Усиленный шов</h5>
                            <p>Высокое давление и импульсное сопротивление длительного цикла укрепляют фильтр и
                                защищают двигатель.
                            </p>
                        </li>
                        <li className="grand">
                            <h5>3. Проволочная сетка</h5>
                            <p>Увеличивает площадь фильтрации и DHC, предотвращая слипание складок.</p>
                        </li>
                        <li className="grand">
                            <h5>6. Торцевая крышка</h5>
                            <p>Превосходное сцепление предотвращает попадание загрязнений.</p>
                        </li>
                    </ul>
                </div>
                <h4 className="content__podtitle">Повреждения, вызванные использованием неоригинальных фильтров</h4>
                <p className="text">Большинство неоригинальных фильтров не соответствуют стандарту качества и значительно
                    уступают по производительности и качеству оригинальным фильтрам DEVELON. Использование неоригинальных фильтров
                    может привести к аномальному абразивному износу двигателя и гидравлической системы, поломке и выходу из строя деталей,
                    одновременно сокращая срок службы двигателя и резко увеличивая затраты на техническое обслуживание. Оригинальные
                    фильтры DEVELON спроектированы и разработаны специально для машин DEVELON. Они созданы в соответствии со строгими
                    критериями компании и тем самым обеспечивают надежную и высококачественную фильтрацию.

                </p>
            </div>
        </div>
    )
}
